import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {Location} from '@angular/common';
import {saveAs} from 'file-saver';
import {Constant} from '../../constants/constants';

@Component({
    selector: 'app-descargar',
    templateUrl: './descargar.component.html',
    styleUrls: ['./descargar.component.scss']
})
export class DescargarComponent implements OnInit {
    correo: any;
    uuid: any;
    urlDescargaPDF: any = '';
    urlDescargaXML: any = '';

    constructor(private _location: Location, private activatedRoute: ActivatedRoute, private http: HttpClient, private toastr: ToastrService) {
        this.correo = this.activatedRoute.snapshot.paramMap.get('correo');
        this.uuid = this.activatedRoute.snapshot.paramMap.get('uuid').toLowerCase();
        this.urlDescargaXML = Constant.api + '/Xml/' + this.uuid + '.xml';
        this.urlDescargaPDF = Constant.api + '/Xml/' + this.uuid + '.pdf';
    }

    ngOnInit(): void {
        var body = document.getElementsByTagName('body')[0];
        body.classList.add('index-page');
    }

    Cancel() {
        this._location.back();
    }

    enviarFactura() {
        this.http.get(Constant.api + '/Api/FacturacionEXT/Correo?email=' + this.correo + '&factura=' + this.uuid).subscribe(data => {
            this.toastr.success('Se ha enviado la factura por correo.', '!');
        }, error => {
            this.toastr.warning('Ha ocurrido un error inesperado! Favor de intentarlo más tarde.', 'Cuidado!');
        });
    }

    descargarXML() {
        this.http.get(this.urlDescargaXML).subscribe(data => {
        }, error => {
            const blob = new Blob([error['error'].text], {type: 'application/octet-stream'});
            saveAs(blob, this.uuid + '.xml');
        });
    }

}
