import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {BlockUI, NgBlockUI, BlockUIService} from 'ng-block-ui';
import {ModalModule} from 'ngx-bootstrap/modal';
import {Location, DatePipe} from '@angular/common';
import {Constant} from '../../constants/constants';

@Component({
    selector: 'app-index',
    templateUrl: 'index.component.html'
})
export class IndexComponent implements OnInit, OnDestroy {
    @BlockUI() blockUI: NgBlockUI;
    @ViewChild('myModal') myModal;
    rfc: any = '';
    ticket: any;
    Alerta: any;
    open: boolean = false;
    clicked: boolean = false;
    timeout: number = 3000;
    seguimientoGuia: any = [];

    constructor(public datepipe: DatePipe, private router: Router, private http: HttpClient, private toastr: ToastrService) {
    }

    scrollToDownload(element: any) {
        element.scrollIntoView({behavior: 'smooth'});
    }

    ngOnInit() {
        var body = document.getElementsByTagName('body')[0];
        body.classList.add('index-page');
    }

    ngOnDestroy() {
        var body = document.getElementsByTagName('body')[0];
        body.classList.remove('index-page');
    }

    facturar() {
        this.clicked = true;
        if (this.rfc == '' || this.rfc == undefined) {
            this.rfc = 0;
        }
        if (this.ticket == '' || this.ticket == undefined) {
            this.toastr.info('Favor de ingresar el número de documentación a facturar!', 'Cuidado!');
            this.clicked = false;
            return;
        }


        this.http.get(Constant.api + '/Api/FacturacionEXT/Validate/' + this.rfc + '/' + this.ticket).subscribe(data2 => {

            this.http.get(Constant.apiGuias + '/Rastreo/' + this.ticket + '/Factura').subscribe(data => {
                this.router.navigate(['/', 'datosfiscales', this.rfc, data['Importe'], this.ticket,data['TipoPago']]);
            }, error => {
                this.clicked = false;
                if (error.error && error.error.Message) {
                    this.toastr.error(error.error.Message, '¡Error!');
                } else if (error.status == 401) {
                    this.toastr.info('Esta guía ya está facturada.', '¡Cuidado!');
                } else if (error.status == 404) {
                    this.toastr.info('No se encontró la guía ingresada.', '¡Cuidado!');
                } else {
                    this.toastr.error('Error en el servidor', '¡Error!');
                }
            });
        }, error => {
            this.clicked = false;
            if (error.error && error.error.Message) {
                this.toastr.error(error.error.Message, '¡Error!');
            } else {
                this.toastr.error('Error en el servidor', '¡Error!');
            }
        });

    }

    rastrear() {
        if (this.ticket == '' || this.ticket == undefined) {
            this.toastr.info('Favor de ingresar el número de documentación para rastrear!', 'Cuidado!');
            this.clicked = false;
            return;
        }
        this.http.get(Constant.apiGuias + '/Rastreo/' + this.ticket).subscribe(data => {
            this.seguimientoGuia = [];
            for (let x = 0; x < data["Pinchazos"].length; x++) {
                this.seguimientoGuia.push({
                    tiempo: this.datepipe.transform(data['Pinchazos'][x]['tiempo'].replace('T', ' '), 'dd/MM/yyyy HH:mm'),
                    estatusdescripcion: data['Pinchazos'][x]['estatusdescripcion']
                });
            }
            this.myModal.show();
        }, error => {
            if (error['status'] == 500) {
                this.toastr.info('No se encontró esta guía, favor de revisar el número cuidadosamente!', 'Cuidado!');
            }
        });
    }

    formatGuia() {
        let sucursal = this.ticket.substring(0, 3);
        let numero = this.ticket.substring(3).padStart(10, '0');
        this.ticket = sucursal.toUpperCase() + numero;
    }

    hide() {
        this.myModal.hide();
    }


}
