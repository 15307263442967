import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Location} from '@angular/common';
import {ToastrService} from 'ngx-toastr';
import {Constant} from '../../constants/constants';

@Component({
    selector: 'app-datosfiscales',
    templateUrl: './datosfiscales.component.html',
    styleUrls: ['./datosfiscales.component.scss']
})
export class DatosfiscalesComponent implements OnInit {
    guia: any;
    rfc: any;
    nocliente: any;
    razonsocial: any;
    calle: any;
    noext: any;
    noint: any;
    colonia: any;
    cp: any;
    telefono: any;
    cuentabancaria: any;
    correo: any;
    passwordZepeda: any;
    usocfdi: any = 'G03';
    formadepago: any = 1;
    total: any = 0;
    normalFields = true;
    importantFields = true;
    buscarGuia: any;
    guias: any = [];
    timbrarActivo = false;
    name: any;
    // firstName: any;
    // lastName: any;
    codeTaxRegime: any;
    codeTaxRegimeList: any;

    constructor(private activatedRoute: ActivatedRoute, private _location: Location, private router: Router, private http: HttpClient, private toastr: ToastrService) {
    }

    ngOnInit(): void {
        this.rfc = this.activatedRoute.snapshot.paramMap.get('rfc');
        this.formadepago = this.activatedRoute.snapshot.paramMap.get('tipopago');
        this.total = parseFloat(this.activatedRoute.snapshot.paramMap.get('total')).toFixed(2);
        this.guia = this.activatedRoute.snapshot.paramMap.get('guia');
        this.guias = [];
        this.guias.push({
            guia: this.guia,
            total: this.total
        });
        const body = document.getElementsByTagName('body')[0];
        body.classList.add('index-page');
        this.codeTaxRegimeList = this.http.get(Constant.api + '/Api/FacturacionEXT/RegimenFiscalList');
        if (this.rfc != 0) {
            this.http.get(Constant.api + '/Api/FacturacionEXT/Cliente?cad=' + this.rfc).subscribe(data => {
                data['datos'] = data;
                if (data['datos'].length > 0) {
                    this.nocliente = data['datos'][0]['Id']['Id'];
                    this.razonsocial = data['datos'][0]['Id']['CommercialName'];
                    this.name = data['datos'][0]['Id']['Name'];
                    // this.firstName = data['datos'][0]['Id']['FirstName'];
                    // this.lastName = data['datos'][0]['Id']['LastName'];
                    this.codeTaxRegime = data['datos'][0]['Id']['CodeTaxRegime'];
                    this.calle = data['datos'][0]['Id']['Street'];
                    this.noext = data['datos'][0]['Id']['NoExt'];
                    this.noint = data['datos'][0]['Id']['NoInt'];
                    this.cp = data['datos'][0]['Id']['CP'];
                    this.correo = data['datos'][0]['Id']['Email'];
                    this.normalFields = false;
                    this.importantFields = true;
                } else {
                    this.getClientFromGuides();
                }
            }, error => {
                this.normalFields = false;
                this.importantFields = false;
            });
        } else {
            this.rfc = '';
            this.normalFields = false;
            this.importantFields = false;
        }
    }

    Cancel() {
        this._location.back();
    }

    async facturar() {
        if (this.razonsocial == '' || this.razonsocial == undefined) {
            this.toastr.info('Favor de ingresar una razón social.');
            return false;
        }
        if (this.rfc == '' || this.rfc == undefined) {
            this.toastr.info('Favor de ingresar RFC válido..');
            return false;
        }
        if (this.cp == '' || this.cp == undefined) {
            this.toastr.info('Favor de ingresar un Código Postal válido.');
            return false;
        }
        if (this.correo == '' || this.correo == undefined) {
            this.toastr.info('Favor de ingresar un correo electrónico válido.');
            return false;
        }
        if (this.codeTaxRegime == '' || this.codeTaxRegime == undefined) {
            this.toastr.info('Seleccione un régimen fiscal.');
            return false;
        }

        let guiasATimbrar = '';
        for (let i = 0; i < this.guias.length; i++) {
            try {
                let rastreoResp = await this.http.get(Constant.apiGuias + '/Rastreo/' + this.guias[i]['guia'] + '/Factura').toPromise();
            }
            catch(error){
                if (error.error && error.error.Message) {
                    this.toastr.error(error.error.Message, '¡Error!');
                } else if (error.status == 401) {
                    this.toastr.info('Esta guía ya está facturada.', '¡Cuidado!');
                } else if (error.status == 404) {
                    this.toastr.info('No se encontró la guía ingresada.', '¡Cuidado!');
                } else {
                    this.toastr.error('Error en el servidor', '¡Error!');
                }
                return;
            }
            guiasATimbrar += this.guias[i]['guia'] + ',';
        }

        if (this.nocliente == '' || this.nocliente == undefined) {
            let data = {
                'Id': 0,
                'Name': this.name,
                'CommercialName': this.razonsocial,
                'CodeTaxRegime': this.codeTaxRegime,
                'RFC': this.rfc,
                'CP': this.cp,
                'Street': this.calle,
                'NoExt': this.noext,
                'NoInt': this.noint,
                'Email': this.correo,
                'IdMunicipio': 1,
                'idEstado': 1
            }
            this.http.post(Constant.api + '/Api/FacturacionEXT/Cliente', data).subscribe(data => {
                this.nocliente = data; // Asigna el ID insertado del nuevo cliente
                this.toastr.success('Te haz registrado correctamente, ahora timbraremos tu factura.');
                
                let data2 = {
                    'Password': this.passwordZepeda,
                    'FormaPago': this.formadepago,
                    'TipoDePago': this.formadepago == 20 ? 'PPD' : 'PUE',
                    'Moneda': 'MXN',
                    'sSerie': 'A',
                    'Folio': 0,
                    'UsoCFDI': this.usocfdi,
                    'esVistaPrevia': false,
                    'RFCCliente': this.rfc,
                    'RazonSocial': this.razonsocial,
                    'CodeTaxRegime': this.codeTaxRegime,
                    'Calle': this.calle,
                    'NoExterior': this.noext,
                    'NoInterior': this.noint,
                    'Colonia': this.colonia,
                    'Localidad': '',
                    'CP': this.cp,
                    'Pais': null,
                    'Estado': 1,
                    'Municipio': 1,
                    'Telefono': null,
                    'Email': this.correo,
                    'Referencia': guiasATimbrar,
                    'TotalNeto': this.total
                }
                debugger;
                this.http.post(Constant.api + '/Api/FacturacionEXT/Facturar', data2).subscribe(data => {
                    this.toastr.success('Se ha timbrado la guía correctamente!', '!');
                    this.router.navigate(['/', 'descargar', data['factura'], this.correo]);
                }, error => {
                    if (error.error && error.error.Message)
                        this.toastr.error(error.error.Message, '¡Error!');
                    else
                        this.toastr.error('La factura no se pudo timbrar. Favor de intentarlo más tarde.');
                });
            }, error => {
                this.toastr.error('La factura no se pudo timbrar. Favor de intentarlo más tarde.');
            });

        } else {
            let clientData = {
                'Id': this.nocliente,
                'Name': this.name,
                'CommercialName': this.razonsocial,
                'CodeTaxRegime': this.codeTaxRegime,
                'RFC': this.rfc,
                'CP': this.cp,
                'Street': this.calle,
                'NoExt': this.noext,
                'NoInt': this.noint,
                'Email': this.correo,
                'IdMunicipio': 1,
                'idEstado': 1
            };
            
            let invoiceData = {
                'Password': this.passwordZepeda,
                'FormaPago': this.formadepago,
                'TipoDePago': this.formadepago == 20 ? 'PPD' : 'PUE',
                'Moneda': 'MXN',
                'sSerie': 'A',
                'Folio': 0,
                'UsoCFDI': this.usocfdi,
                'esVistaPrevia': false,
                'RFCCliente': this.rfc,
                'RazonSocial': this.razonsocial,
                'CodeTaxRegime': this.codeTaxRegime,
                'Calle': this.calle,
                'NoExterior': this.noext,
                'NoInterior': this.noint,
                'Colonia': this.colonia,
                'Localidad': '',
                'CP': this.cp,
                'Pais': null,
                'Estado': 1,
                'Municipio': 1,
                'Telefono': null,
                'Email': this.correo,
                'Referencia': guiasATimbrar,
                'TotalNeto': this.total,
                'customer': clientData
            }
            debugger;
            this.http.post(Constant.api + '/Api/FacturacionEXT/Facturar', invoiceData).subscribe(invoiceResp => {
                this.toastr.success('Se ha timbrado la guía correctamente!', '!');
                this.router.navigate(['/', 'descargar', invoiceResp['factura'], this.correo]);
            }, error => {
                if (error.error && error.error.Message)
                    this.toastr.error(error.error.Message, '¡Error!');
                else
                    this.toastr.error('La factura no se pudo timbrar. Favor de intentarlo más tarde.');
            });
        }
    }

    agregarGuia() {
        if (this.buscarGuia == '') {
            this.toastr.info('Favor de capturar una guía.');
            return;
        }

        this.http.get(Constant.apiGuias + '/Rastreo/' + this.buscarGuia + '/Factura?tipoPago=' + this.formadepago).subscribe(data => {
            this.buscarGuia = '';
            this.guias.push({
                guia: data['Guia'],
                total: data['Importe'].toFixed(2)
            });
            this.total = 0;
            for (let x = 0; x < this.guias.length; x++) {
                this.total += parseFloat(this.guias[x].total);
            }
            this.total = this.total.toFixed(2);
            this.timbrarActivo = false;
        }, error => {
            if (error.error && error.error.Message) {
                this.toastr.error(error.error.Message, '¡Error!');
            } else if (error.status == 401) {
                this.toastr.info('Esta guía ya está facturada.');
            }
            if (error.status == 404) {
                this.toastr.info('No se encontró la guía ingresada.');
            }
        });
    }

    quitarGuia(e, i) {
        this.guias.splice(i, 1);
        this.total = 0;
        for (let x = 0; x < this.guias.length; x++) {
            this.total += parseFloat(this.guias[x].total);
        }
        this.total = this.total.toFixed(2);
        if (this.guias.length == 0) {
            this.timbrarActivo = true;
        }
    }

    formatGuia() {
        let sucursal = this.buscarGuia.substring(0, 3);
        let numero = this.buscarGuia.substring(3).padStart(10, '0');
        this.buscarGuia = sucursal + numero;
    }

    getClientFromGuides() {
        this.http.get(Constant.apiGuias + '/Rastreo/RFC/' + this.rfc).subscribe(data => {
            data['datos'] = data;
            if (data['datos']) {
                this.razonsocial = data['datos']['Client']['razonsocial'];
                this.name = `${data['datos']['PersonaInformation']['nombres']} ${data['datos']['PersonaInformation']['apellidos']}`;
                // this.firstName = data['datos']['PersonaInformation']['nombres'];
                // this.lastName = data['datos']['PersonaInformation']['apellidos'];
                this.calle = data['datos']['Direction']['calle'];
                this.noext = data['datos']['Direction']['noext'];
                this.noint = data['datos']['Direction']['noint'];
                this.cp = data['datos']['Direction']['cp'];
                this.correo = data['datos']['Client']['correo'];
                this.importantFields = true;
            } else
                this.importantFields = false;
            this.normalFields = false;
        }, error => {
            this.normalFields = false;
            this.importantFields = false;
        });
    }
}
